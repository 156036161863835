import "./SeoKeywords.style.scss";

export const SeoKeywords = () => {
    return (
        <div className={"seo-keywords"}>
            <h1>Électricité coupure</h1>
            <h1>Électricité norme</h1>
            <h1>Électricité installation</h1>
            <h1>Électricité dépannage</h1>
            <h1>Électricité pas cher</h1>
            <h1>Électricité Gardanne</h1>
            <h1>Électricité Aix-en-Provence</h1>
        </div>
    )
}